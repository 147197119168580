import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faPagelines } from '@fortawesome/free-brands-svg-icons';

const IntroductionSection = () => {
    return (
        <div className="relative h-[90vh] bg-fixed bg-cover bg-center" style={{ backgroundImage: "url('/london.jpg')" }}>
            <div className="absolute inset-0 bg-black opacity-10"></div>
            <div className="relative flex justify-center items-center h-full">
                <div className={`text-white text-center ${global.config.settings.main.content_width}`}>
                    <div className="flex flex-col lg:flex-row justify-center items-center bg-white">
                        <div className="w-full lg:w-1/2" style={{ backgroundImage: `url(${global.config.about.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className="h-[50vh]"></div>
                        </div>
                        <div className="w-1/2 space-y-2 p-2 py-10">
                            <h1 className="text-3xl text-gray-900 ml-5">{global.config.about.name}</h1>
                            <p className="text-gray-700 ml-5 font-semibold text-sm">
                                {global.config.about.institution}
                                <br />
                                {global.config.about.degree}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center bg-primary p-4 text-white font-extrabold space-x-5">
                        {global.config.about.social.linkedin && (
                            <div>
                                <a href={global.config.about.social.linkedin.link} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} className="pr-2" />
                                    {global.config.about.social.linkedin.name}
                                </a>
                            </div>
                        )}
                        {global.config.about.social.github && (
                            <div>
                                <a href={global.config.about.social.github.link} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faGithub} className="pr-2" />
                                    {global.config.about.social.github.name}
                                </a>
                            </div>
                        )}
                        {global.config.about.social.cv && (
                            <div>
                                <a
                                    href={global.config.about.social.cv.link}
                                    download={global.config.about.social.cv.download ? "ICV-2024.pdf" : undefined}
                                    target={global.config.about.social.cv.download ? undefined : "_blank"}
                                    rel={global.config.about.social.cv.download ? undefined : "noopener noreferrer"}
                                >
                                    <FontAwesomeIcon icon={faPagelines} className="pr-2" />
                                    {global.config.about.social.cv.name}
                                </a>
                            </div>
                        )}
                    </div>  
                </div>
            </div>
        </div>
    )
}

export default IntroductionSection

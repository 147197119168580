/**
 * 
 * 
 * @returns 
 * 
 * npm i react-axios
 * 
*/

import { useEffect, useState } from "react";
import axios from "axios";

// The whole Blog Server component.
const BlogServer = () => {

    /**
     * 
     * The state that holds the posts.
     * useState is a React Hook that lets you add a state variable to your component.
     * 
     */
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const endpoint = '/wp-json/wp/v2/posts?_embed';
    
    /**
     * 
     * useEffect = CSR (Client Side Rendering)
     * useEffect Hook allows you to perform side effects in your components. 
     * Examples fetching data, directly updating the DOM, and timers.
     * 
     */
    useEffect(() => {

        // Make an async call to the API
        const fetchData = async () => {
            try {

                // Make the first API call
                const idcd = await axios.get(global.config.settings.blog.sources.idcd.api + endpoint, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                // Make the second API call
                const imperial = await axios.get(global.config.settings.blog.sources.imperial.api + endpoint, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                // Combine the results
                const response = [...idcd.data, ...imperial.data];

                // Set the posts state
                setPosts(response);

            } catch (error) {
                // Handle error
                console.error(error);
            } finally {
                // Set loading to false
                setLoading(false);
            }
        };

        // Call the fetchData function
        fetchData();

    }, []);

    return { posts, loading };
};

export default BlogServer;
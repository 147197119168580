import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './index.css'; 
import './config.js';


import Home from './pages/Home';
import NotFound from './pages/NotFound';
import HeaderComponent from './components/header';
import FooterComponent from './components/footer';



function App() {

  return (

    <Router>

      {/* md:mx-10 */}
      <div className={global.config.settings.main.margin}>

        <HeaderComponent />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

        <FooterComponent />

      </div>

    </Router>

  );
  
}

export default App;

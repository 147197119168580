

const LanguagesSection = ({ languagesArray }) => {
    return (
        <div className="flex justify-center items-center py-10 w-full h-[25vh]">
            <div className={`flex flex-row flex-wrap ${global.config.settings.main.content_width}`}>
                {
                    // list of languages
                    languagesArray.map((language, index) => (
                        <div key={index} className="flex items-center justify-stretch p-4 w-1/2 md:w-1/3">
                            <div className="ml-4">
                                <p className="text-lg font-semibold">{language.name}</p>
                                <p className="text-sm font-light">{language.level}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default LanguagesSection;
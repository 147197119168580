import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import React from 'react';

const FooterComponent = () => {
    return (
        <footer>
            <div className="bg-ternary text-center pt-10 relative flex justify-center items-center h-full">
                <div className={`items-center ${global.config.settings.main.content_width}`}>
                    <div className="flex justify-center items-center py-8 space-x-5" id="contact">
                        <div className="md:w-1/2 text-center">
                            <div className="uppercase text-primary text-xl tracking-wider">
                                Contact Me
                            </div>
                        </div>
                    </div>
                    <div className="md:flex">
                        <div className="w-full md:w-1/2 text-right p-16 border-t border-white order-2 md:order-1">
                            <form action="" method="post">
                                <input type="text" name="name" placeholder="Name" className="w-full p-2 my-2" />
                                <input type="email" name="email" placeholder="Email" className="w-full p-2 my-2" />
                                <textarea name="message" placeholder="Message" className="w-full p-2 my-2"></textarea>
                                <button type="submit" className="w-full bg-primary text-white p-2 my-2">Send</button>
                            </form>
                        </div>
                        <div className="w-full md:w-1/2 text-left p-16 border-t border-l border-white order-1 md:order-2">
                            <h1 className="text-3xl font-semibold text-gray-900">Oliver Toparti</h1>
                            <br />
                            <div className="text-gray-700 font-semibold text-sm">
                                Imperial College London <br />
                                MSc Security and Resilience '25
                                <br /><br />
                                Email:
                                <br />
                                <a href={"mailto:" + global.config.about.social.email.link} className="text-primary font-light">
                                    {global.config.about.social.email.link}
                                </a>
                                <br />
                                <span className="block h-px bg-gray-500 my-2 w-full"></span>
                                <div className="flex text-left font-extrabold space-x-5">
                                    <div>
                                        <a href={global.config.about.social.linkedin.link} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faLinkedin} className="pr-2" />
                                            {global.config.about.social.linkedin.name}
                                        </a>
                                    </div>
                                    <div>
                                        <a href={global.config.about.social.github.link} target="_blank" rel="noreferrer">
                                            <FontAwesomeIcon icon={faGithub} className="pr-2" />
                                            {global.config.about.social.github.name}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;
/**
 * 
 * 
 * ListingSection Component
 * 
 * Returns a section of list.
 * 
 * 
*/

const ListingSection = ({infoArray}) => {

    return (
        <div className="flex justify-center items-center py-10 w-full">
            <div className="space-y-5">
                {infoArray.map((info, index) => (
                    <div key={index} className="flex justify-center">
                        <div className={`flex justify-center items-center p-5 md:p-10 space-x-10 ${global.config.settings.main.content_width}`}>
                            <div className="md:flex justify-center space-y-4 items-center md:space-x-5 w-1/2">
                                <div className="w-full md:w-1/2">
                                    <img src={info.company.img_src} alt={info.company.name + ' Logo'} width={150} />
                                </div>
                                <div className="w-full md:w-1/2 md:text-right space-y-1 text-sm">
                                    <p className="">{info.position}</p>
                                    <p className="font-semibold">{info.years}</p>
                                    <a className="font-extralight" href={info.company.url}>{info.company.name + ', '} <br/> {info.location}</a>
                                </div>
                            </div>
                            {/** THIN LINE BETWEEN WITH DOT MIDDLE OF LINE */}
                            <div className="hidden md:block h-2/3 w-0.5 bg-gray-300 relative">
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2 h-2 bg-gray-400 rounded-full"></div>
                            </div>
                            {/** THIN LINE BETWEEN */}
                            <div className="w-1/2 space-y-3 text-left text-sm"> 
                                {/** Interpret as html */}
                                <p className="font-extralight leading-6" dangerouslySetInnerHTML={{__html: info.summary}}></p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ListingSection;
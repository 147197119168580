import React from 'react';
import BlogServer from "../../services/blog-server";

const BlogSection = () => {
    const { posts, loading } = BlogServer();

    // Debug ...
    // console.log("Loading:", loading);
    // console.log("Posts:", posts);

    if (loading) {
        return (
            <div>Loading...</div>
        );
    }

    return (
        <div className="flex justify-center items-center py-10 w-full">
            <div className={`flex flex-row flex-wrap ${global.config.settings.main.content_width}`}>
                {
                    posts && posts.length > 0 ? (
                        posts.map((post) => (
                            <div key={post.id} className="flex items-center justify-stretch p-4 w-1/2 md:w-1/3">
                                <div className="space-y-3">
                                    <div className="overflow-hidden">
                                        {
                                            // background fill should be {post._embedded['wp:featuredmedia'][0].source_url}
                                            // when I zoom the image should zoom and should go dark
                                        }
                                        <div className="h-40 w-full bg-cover bg-center rounded-sm bg-image-zoom" style={{ backgroundImage: `url(${post._embedded['wp:featuredmedia'][0].source_url})` }}></div>
                                    </div>


                                    <div className="p-2 bg-primary rounded-sm text-white text-sm">
                                        {
                                            post.link.includes(global.config.settings.blog.sources.idcd.api) ? (
                                                <a href={global.config.settings.blog.sources.idcd.link} target="_blank" rel="noreferrer" className="font-bold">
                                                    {global.config.settings.blog.sources.idcd.name}
                                                </a>
                                            ) : post.link.includes(global.config.settings.blog.sources.imperial.api) ? (
                                                <a href={global.config.settings.blog.sources.imperial.link} target="_blank" rel="noreferrer" className="font-bold">
                                                    {global.config.settings.blog.sources.imperial.name}
                                                </a>
                                            ) : null
                                        }
                                    </div>
                                    <h2 className="text-lg font-bold line-clamp-2">{post.title.rendered}</h2>
                                    <div>
                                        {
                                            // take the post.api. take the last endpoint i.e. /cybersecurity-in-diabetes-healthcare-protecting-patients-and-data and add it to https://www.idcd.org.uk/publications/
                                            post.link.includes(global.config.settings.blog.sources.idcd.api) ? (
                                                <a href={global.config.settings.blog.sources.idcd.link + 'publications/' + post.link.split('/').slice(-2, -1).join('/')} target="_blank" rel="noreferrer" className="underline">
                                                    Read More
                                                </a>
                                            ) : (
                                                <a href={post.link} target="_blank" rel="noreferrer" className="underline">
                                                    Read More
                                                </a>
                                            )
                                        }
                                    </div>
                                    {
                                        // Convert date to human readable format
                                    }
                                    <p>
                                        {new Date(post.date).toDateString()}
                                    </p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div>No posts available</div>
                    )
                }
            </div>
        </div>
    );
};

export default BlogSection;


const Separator = ({ title, anchor }) => {

    return (
        <div className="flex justify-center items-center bg-primary py-8 space-x-5" id={anchor}>
            <div className={`md:w-1/2 text-center ${global.config.settings.main.content_width}`}>
                <div className="uppercase text-white text-xl tracking-wider">
                    {title}
                </div>
            </div>
        </div>
    )
}

export default Separator
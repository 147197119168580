import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const HeaderComponent = () => {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);





    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth > 768 && isMobileMenuOpen) {
                setIsMobileMenuOpen(false);
            }
        };
        // Add event listener
        window.addEventListener('resize', handleResize);
        handleResize();
        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, [isMobileMenuOpen]);
    // Determine if it's mobile view.
    const isMobileView = windowWidth < 768;


    // Splitting the links array into two arrays
    const links = [
        {
            name: 'Home',
            url: '/'
        },
        {
            name: 'Experience',
            url: '#experience'
        },
        {
            name: 'Education',
            url: '#education'
        },
        {
            name: 'Languages',
            url: '#languages'
        },
        {
            name: 'Blog',
            url: '#blog'
        },
        {
            name: 'Contact',
            url: '#contact'
        }
    ];

    // Array 1
    const arraySplit1 = links.slice(0, links.length / 2);
    // Array 2
    const arraySplit2 = links.slice(links.length / 2, links.length);

    return (
        <header className="sticky top-0 z-50">
            <div className="bg-primary text-white px-10 py-2">
                {
                    !isMobileView ? (
                        <div className="flex justify-between items-center h-16">
                            <nav role="navigation" className="flex justify-center items-center w-full">
                                <div className="flex justify-center items-center w-full">
                                    <div className="flex-1 flex justify-start space-x-8 font-light uppercase text-sm">
                                        {arraySplit1.map(link => (
                                            <a key={link.name} href={link.url}>{link.name}</a>
                                        ))}
                                    </div>
                                    <div className="flex-shrink-0">
                                        <img src="./logo.png" alt="Logo" width="135" height="135" />
                                    </div>
                                    <div className="flex-1 flex justify-end space-x-8 font-light uppercase text-sm">
                                        {arraySplit2.map(link => (
                                            <a key={link.name} href={link.url}>{link.name}</a>
                                        ))}
                                    </div>
                                </div>
                            </nav>
                        </div>
                    ) : (
                        // Mobile view with hamburger menu
                        <div className="flex justify-between items-center h-16">
                            <div className="flex items-center w-full">
                                <div className="w-1/3">
                                    <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="block text-white hover:text-white focus:text-white focus:outline-none">
                                        <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="" strokeLinejoin="" strokeWidth="1" d="M4 6h16M4 12h16m-7 6h7"></path>
                                        </svg>
                                    </button>
                                </div>
                                <div className="w-1/3">
                                    <div className="flex-shrink-0">
                                        <Link href="/home">
                                            <img src="./logo.png" alt="Logo" width="135" height="135" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    isMobileMenuOpen ? (
                        <nav role="navigation" className="absolute w-full bg-primary shadow-md z-50 right-0 left-0 mt-2">
                            <div className="flex flex-col items-center justify-center">
                                {
                                    links.map(link => (
                                        <a key={link.name} href={link.url} className="py-4 px-8 text-left w-full text-white hover:bg-secondary transition duration-150 ease-in-out">{link.name}</a>
                                    ))
                                }
                            </div>
                        </nav>
                    ) : null
                }

            </div>
        </header>
    );
}

export default HeaderComponent;
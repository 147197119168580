import React from "react";

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-[30vw]">
            <h1 className="text-3xl font-bold">404</h1>
            <p className="text-lg">Page not found</p>
        </div>
    );
}

export default NotFound;